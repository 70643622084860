import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import BeneficiosSeguridad from '../../components/BeneficiosSeguridad';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';
import MarcasSeguridad from '../../components/MarcasSeguridad';


import logoseguridad from '../../assets/images/seguridad/logoseguridad.png';

import path_to_icon1 from '../../assets/images/seguridad/ciberseguridadgestionada/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/seguridad/ciberseguridadgestionada/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/seguridad/ciberseguridadgestionada/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/seguridad/ciberseguridadgestionada/path_to_icon4.png';

import path_to_icon5 from '../../assets/images/seguridad/ciberseguridadgestionada/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/seguridad/ciberseguridadgestionada/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/seguridad/ciberseguridadgestionada/path_to_icon7.png';
import path_to_icon8 from '../../assets/images/seguridad/ciberseguridadgestionada/path_to_icon8.png';

import marca1 from '../../assets/images/marcas/logo_cisco.png';
import marca2 from '../../assets/images/marcas/logo_Fortinet.png';
import marca3 from '../../assets/images/marcas/logo_Huawei.png';
import marca4 from '../../assets/images/marcas/logo_Microsoft.png';
import marca5 from '../../assets/images/marcas/logo_Omnissa.png';
import marca6 from '../../assets/images/marcas/logo_Paloalto.png';
import marca7 from '../../assets/images/marcas/logo_Sophos.png';
import marca8 from '../../assets/images/marcas/logo_splunk.png';
import marca9 from '../../assets/images/marcas/logo_Telxius.png';
import marca10 from '../../assets/images/marcas/logo_Veeam.png';


const CiberseguridadGestionada = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Ahorro</b> de costos y tiempo en capacitar tu personal en ciberseguridad. Delegando en nuestro equipo de profesionales certificados la gestión de ciberseguridad de tus activos.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Disponibilidad</b> de un amplio equipo multidisciplinario de especialistas en diferentes tecnologías, capaces de entregar el servicio 24x7 los 365 días del año.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Escabilidad</b> al brindar un servicio que se adapta y crece a la par de las necesidades de tu organización a través de nuestros SOC distribuidos.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Simplicidad </b> en los procesos buscando siempre la mejora continua en nuestros procesos y servicios.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon5,
      text: '<strong>Propuesta</strong> Diseño de soluciones personalizadas.'
    },
    {
      icon: path_to_icon6,
      text: '<strong>Equipo</strong> Más de 1500 expertos en seguridad con certificaciones.'
    },
    {
      icon: path_to_icon7,
      text: '<strong>Tecnología</strong> Más de 50 partners integran sus tecnologías en nuestros productos y servicios.'
    },
    {
      icon: path_to_icon8,
      text: '<strong>Servicio</strong> 11iSOCs con presencia local y alcance global 7x24.'
    }
  ];

  const marcas = [
    {
      icon: marca1,
    },
    {
      icon: marca2,
    },
    {
      icon: marca3,
    },
    {
      icon: marca4,
    },
    {
      icon: marca5,
    },
    {
      icon: marca6,
    },
    {
      icon: marca7,
    },
    {
      icon: marca8,
    },
    {
      icon: marca9,
    },
    {
      icon: marca10,
    }
  ];



  return (
    <div className='container no-video'>
      <TitleService
        linkTo="/"
        imgSrc={logoseguridad}
        imgAlt="Logo de seguridad digital"
        description="Protegé la información de tu empresa con servicios personalizados y tecnologías de última generación, fortaleciendo tu postura de seguridad de manera profesional y eficaz, asegurando el acceso continuo a la información."
      />

      <SubHeader
        title="Ciberseguridad <b>Gestionada</b>"
        subtitle1="Familia de servicios destinados a proporcionar la gestión de las diferentes plataformas de seguridad de la organización mediante un equipo de especialistas capacitados y expertise en ciberseguridad."
        subtitle2="Proporciona de forma adicional la gestión de incidentes de seguridad con nuestro equipo de especialistas. De forma que la organización pueda enfocarse en las tareas de mayor valor para el negocio."
      />

      <MarqueeText text="Ciberseguridad Gestionada" />

      <BeneficiosSeguridad/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />

      <MarcasSeguridad marcas={marcas} />

      
    </div>
  );
};

export default CiberseguridadGestionada;
