import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import VideoContainer from '../../components/VideoContainer';
import BeneficiosSeguridad from '../../components/BeneficiosSeguridad';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';
import MarcasSeguridad from '../../components/MarcasSeguridad';

import logoseguridad from '../../assets/images/seguridad/logoseguridad.png';

import path_to_icon1 from '../../assets/images/seguridad/seguridadmdm/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/seguridad/seguridadmdm/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/seguridad/seguridadmdm/path_to_icon3.png';

import path_to_icon4 from '../../assets/images/seguridad/seguridadmdm/path_to_icon4.png';
import path_to_icon5 from '../../assets/images/seguridad/seguridadmdm/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/seguridad/seguridadmdm/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/seguridad/seguridadmdm/path_to_icon7.png';
import path_to_icon8 from '../../assets/images/seguridad/seguridadmdm/path_to_icon8.png';
import path_to_icon9 from '../../assets/images/seguridad/seguridadmdm/path_to_icon9.png';

import marca1 from '../../assets/images/marcas/logo_cisco.png';
import marca2 from '../../assets/images/marcas/logo_Fortinet.png';
import marca3 from '../../assets/images/marcas/logo_Huawei.png';
import marca4 from '../../assets/images/marcas/logo_Microsoft.png';
import marca5 from '../../assets/images/marcas/logo_Omnissa.png';
import marca6 from '../../assets/images/marcas/logo_Paloalto.png';
import marca7 from '../../assets/images/marcas/logo_Sophos.png';
import marca8 from '../../assets/images/marcas/logo_splunk.png';
import marca9 from '../../assets/images/marcas/logo_Telxius.png';
import marca10 from '../../assets/images/marcas/logo_Veeam.png';



const SeguridadMdm = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>VISIBILIDAD</b> de los dispositivos y la información de la organización, brindando integridad, confidencialidad y disponibilidad de los datos.'
    },
    {
      icon: path_to_icon2,
      text: '<b>CONTROL</b> de la información en los dispositivos, para gestionar de manera centralizada el acceso, uso y distribución de datos sensibles sólo a los usuarios autorizados.'
    },
    {
      icon: path_to_icon3,
      text: '<b>CUMPLIMIENTO</b> de normas y marcos de ciberseguridad y seguridad de la información, a través de políticas robustas en todos tus dispositivos.</b> el acceso de empleados a las aplicaciones y recursos para lograr mayor productividad.'
    },
    {
      icon: path_to_icon4,
      text: '<b>SEGURIDAD</b> al forzar el uso de aplicaciones y firmware autorizadas para los dispositivos de la organización, brindando una defensa integral contra amenazas conocidas.'
    },
    {
      icon: path_to_icon5,
      text: '<b>EFICIENCIA Y PRODUCTIVIDAD</b> al centralizar la gestión de forma masiva. Ahorrando tiempo y recursos para incrementar la eficiencia de las operaciones.'
    },

  ];

  const reasons = [
    {
      icon: path_to_icon6,
      text: 'Configuración y control centralizado de todos los dispositivos móviles.'
    },
    {
      icon: path_to_icon7,
      text: 'Seguridad personalizables para proteger datos confidenciales.'
    },
    {
      icon: path_to_icon8,
      text: 'Cumplimiento normativo y regulaciones de seguridad.'
    },
    {
      icon: path_to_icon9,
      text: 'Soporte 24 horas los 365 días del año.'
    }
  ];

  const marcas = [
    {
      icon: marca1,
    },
    {
      icon: marca2,
    },
    {
      icon: marca3,
    },
    {
      icon: marca4,
    },
    {
      icon: marca5,
    },
    {
      icon: marca6,
    },
    {
      icon: marca7,
    },
    {
      icon: marca8,
    },
    {
      icon: marca9,
    },
    {
      icon: marca10,
    }
  ];

  return (
    <div className='container'>
      <TitleService
        linkTo="/"
        imgSrc={logoseguridad}
        imgAlt="Logo de seguridad digital"
        description="Protegé la información de tu empresa con servicios personalizados y tecnologías de última generación, fortaleciendo tu postura de seguridad de manera profesional y eficaz, asegurando el acceso continuo a la información."
      />
      <SubHeader
        title="Seguridad Móvil <b>SMDM</b>"
        subtitle1="Seguridad Móvil - SMDM (del inglés Secure Mobile Device Management) es una plataforma diseñada para ayudar a las organizaciones a gestionar y controlar de manera eficiente y segura los dispositivos móviles utilizados en su entorno empresarial."
        subtitle2="Con Seguridad Móvil, las organizaciones pueden alcanzar un enfoque centralizado y simplificado para la administración de los dispositivos móviles utilizados en la organización, asegurando la protección de sus datos y mejorando la productividad."
      />

      <MarqueeText text="Seguridad Móvil SMDM" />

      <VideoContainer
        videoSrc="https://www.youtube.com/embed/hX1vGQAewjg?si=B5xxYg7QO2Rq0ws3"
        floatText="Protege los dispositivos móviles contra amenazas de ciberseguridad."
        bottomText="Protege toda la información y comparte datos de forma segura."
      />

      <BeneficiosSeguridad/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />

      <MarcasSeguridad marcas={marcas} />
      
    </div>
  );
};

export default SeguridadMdm;
