import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import BeneficiosSeguridad from '../../components/BeneficiosSeguridad';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';
import MarcasSeguridad from '../../components/MarcasSeguridad';


import logoseguridad from '../../assets/images/seguridad/logoseguridad.png';

import path_to_icon1 from '../../assets/images/seguridad/siem/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/seguridad/siem/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/seguridad/siem/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/seguridad/siem/path_to_icon4.png';

import path_to_icon6 from '../../assets/images/seguridad/siem/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/seguridad/siem/path_to_icon7.png';
import path_to_icon8 from '../../assets/images/seguridad/siem/path_to_icon8.png';


import marca1 from '../../assets/images/marcas/logo_cisco.png';
import marca2 from '../../assets/images/marcas/logo_Fortinet.png';
import marca3 from '../../assets/images/marcas/logo_Huawei.png';
import marca4 from '../../assets/images/marcas/logo_Microsoft.png';
import marca5 from '../../assets/images/marcas/logo_Omnissa.png';
import marca6 from '../../assets/images/marcas/logo_Paloalto.png';
import marca7 from '../../assets/images/marcas/logo_Sophos.png';
import marca8 from '../../assets/images/marcas/logo_splunk.png';
import marca9 from '../../assets/images/marcas/logo_Telxius.png';
import marca10 from '../../assets/images/marcas/logo_Veeam.png';

const Siem = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Detecta de forma temprana un incidente</b> antes de que ocasione daños reales a la producción.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Identifica anomalías de comportamiento</b> como problemas de funcionamiento o incluso incidentes.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Análisis forense.</b> Almacena y consulta eventos antiguos para identificar cómo se produjo un incidente'
    },
    {
      icon: path_to_icon4,
      text: '<b>Centraliza la información</b> permitiendo una gestión centralizada de todos los datos almacenados y así también <strong>ahorrar recursos</strong>.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon6,
      text: '<strong>Prevención proactiva de inidentes</strong> Identifica y previene proactivamente futuros incidentes mediante la correlación de eventos y el análisis de comportamiento.'
    },
    {
      icon: path_to_icon7,
      text: '<strong>Visibilidad completa de la seguridad</strong> Proporciona una visión integral de la actividad en los sistemas, redes, bases de datos, actividad de usuarios y aplicaciones de la organización.'
    },
    {
      icon: path_to_icon8,
      text: '<strong>Monitoreo centralizado 24x7</strong>, Monitoreo continuo de los activos de la organización por parte de un Centro de Operaciones de Seguridad (SOC).'
    }
  ];


  const marcas = [
    {
      icon: marca1,
    },
    {
      icon: marca2,
    },
    {
      icon: marca3,
    },
    {
      icon: marca4,
    },
    {
      icon: marca5,
    },
    {
      icon: marca6,
    },
    {
      icon: marca7,
    },
    {
      icon: marca8,
    },
    {
      icon: marca9,
    },
    {
      icon: marca10,
    }
  ];



  return (
    <div className='container no-video'>
      <TitleService
        linkTo="/"
        imgSrc={logoseguridad}
        imgAlt="Logo de seguridad digital"
        description="Protegé la información de tu empresa con servicios personalizados y tecnologías de última generación, fortaleciendo tu postura de seguridad de manera profesional y eficaz, asegurando el acceso continuo a la información."
      />

      <SubHeader
        title="<b>SIEM</b>"
        subtitle1="Provee a las organizaciones de una solución de visibilidad integral, que permite identificar ciberamenazas de forma rápida y precisa."
        subtitle2="Permite sincronizar, Monitorear y triangular eventos e incidentes de ciberseguridad mediante el análisis de múltiples activos o fuentes de información, como firewalls, antivirus, y muchos otros activos más. Nuestro servicio consiste en el monitoreo de los activos de la organización, correlacionando y analizando eventos, mediante su comportamiento, machine learning e IA." />


      <MarqueeText text="SIEM" />

      <BeneficiosSeguridad/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />

      <MarcasSeguridad marcas={marcas} />s
      
    </div>
  );
};

export default Siem;
