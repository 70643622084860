import React, { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es.js';

const MarcasSeguridad = ({ marcas }) => {
  const marcasRef = useRef(null);

  useEffect(() => {
    // Animación de la sección
    anime({
      targets: marcasRef.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: 4000,
      duration: 1000,
      easing: 'easeOutExpo',
    });

    // Animación del gradiente con movimiento horizontal continuo
    const animateGradient = () => {
      anime({
        targets: '#gradient',
        x1: ['0%', '100%'],
        x2: ['100%', '200%'],
        easing: 'linear',
        duration: 5000,
        loop: true,
        direction: 'alternate'
      });
    };
    
    animateGradient();
  }, []);

  return (
    <section className='marcas-section' ref={marcasRef} style={{ background: 'none' }}>
      <div className='marcascontainer'>
        {marcas.map((marca, index) => (
          <div className='marcas' key={index}>
            <img src={marca.icon} alt={`Icon ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default MarcasSeguridad;
